<template>
  <div id="dashboard">

    <div class="row no-gutters">
      <app-title title="mdl.attivazione.title"></app-title>

    </div>
    <div class="row no-gutters">
      <div class="col-4 offset-4 mt-5">
        <div class="o-registration-collapse__success" v-if="success">
          <p><strong>{{$t('mdl.attivazione.success-heading')}}</strong></p><br>
          <p>{{$t('mdl.attivazione.success-text')}}</p>
          <div class="col-12 mt-5" style="text-align: center">
            <b-button  variant="primary"  size="md"  @click="$router.push('/mdl')">{{ $t("mdl.attivazione.home") }}
            </b-button>
          </div>
        </div>
        <div class="o-registration-collapse__error" v-if="!success">
          <p><strong>{{$t('mdl.attivazione.fail-heading')}}</strong></p><br>
          <p>{{$t('mdl.attivazione.fail-text')}}</p>
          <p>{{$t('mdl.attivazione.errors.'+errorStr)}}</p>
          <div class="col-12 mt-5 text-center" >
            <b-button  variant="outline-primary"  size="md"  @click="$router.push('/mdl')">{{ $t("mdl.attivazione.indietro") }}
            </b-button>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>

import AppTitle from "@/components/molecules/AppTitle";
export default {
  name: "dashboard",
  components: {AppTitle},
  props: {},
  data() {
    return {
      visible:false
    };
  },
  computed:{
    errorStr(){
      let erro=this.$route.query.error;
      if (erro==null)
        return "ERR_NO_ERROR";
      return erro;
    },
    success(){
      let esito=this.$route.query.esito;
      if (esito==null)
        return false;
      return (esito==="success");
    }
  },
  created() {},
  methods: {}
};
</script>
